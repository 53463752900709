<template>
  <Layout v-if="workstation">
    <div v-if="loading">
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </div>
    <div v-else class="p-3">
      <b-tabs content-class="mt-3">
        <b-tab title="Details" active>
          <div class="mb-2">
            <div class="row">
              <div class="col-6">Name : {{ workstation.name }}</div>
              <div class="col-6">City : {{ workstation.city }}</div>
            </div>
          </div>
          <GmapMap
            :center="{
              lat: workstation.location.coordinates[0],
              lng: workstation.location.coordinates[1],
            }"
            :zoom="10"
            map-style-id="roadmap"
            style="width: 100%; height: 250px"
          >
            <GmapMarker
              :position="{
                lat: workstation.location.coordinates[0],
                lng: workstation.location.coordinates[1],
              }"
            />
          </GmapMap>
          <h6 class="mt-4">Work Time</h6>
          <div class="row m-2">
            <div
              v-for="(day, i) in workstation.working_time"
              :key="i"
              class="col-sm-3 card text-center py-3"
            >
              <strong> {{ i }}</strong>
              <div>
                Start Time : {{ day["start_time"] }} <br />
                End Time : {{ day["end_time"] }}
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Jobs">
          <AssignedJob />
        </b-tab>
      </b-tabs>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import AssignedJob from "@/components/workstations/assigned-job";
import { workstations } from "@/config/api/workstations";
export default {
  components: {
    Layout,
    AssignedJob,
  },
  data() {
    return {
      loading: false,
      work_station_id: "",
      workstation: null,
    };
  },
  mounted() {
    if (this.$route.query) {
      this.work_station_id = this.$route.query.id;
      this.loading = true;
      this.getWorkStationsInfo();
    }
  },
  methods: {
    getWorkStationsInfo() {
      const api = workstations.byId;
      api.id = this.work_station_id;
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.workstation = res.data.workstation;
          console.log(this.workstation);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
