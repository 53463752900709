<template>
  <div>
    <b-button v-b-modal="`evidence-${id}`" variant="primary" size="sm"
      >View Evidence</b-button
    >

    <b-modal :id="`evidence-${id}`" title="Evidence" hide-footer>
      <div class="row">
        <div class="col-4" v-for="item in evidences" :key="item._id">
            <div class="evidence-card">
   <img :src="item.image" alt="" srcset="" width="100" />
          <div>
            {{ item.description }}
          </div>
            </div>
       
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["id", "evidences"],
};
</script>

<style>
.evidence-card{
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 4px;
  margin:4px;
  text-align: center;
}
</style>