<template>
  <div>
    <div v-if="loading">
      <div style="width: 100px" class="m-auto">
        <b-spinner type="grow" label="Spinning" variant="success"></b-spinner>
        <b-spinner type="grow" label="Spinning"></b-spinner>
        <b-spinner type="grow" label="Spinning" variant="warning"></b-spinner>
      </div>
    </div>
    <div v-else>
      <div v-if="!data.length" class="text-center my-4">
        <h6>No Jobs</h6>
      </div>
      <div v-else>
        <div class="d-flex justify-content-end">
          <b-dropdown right variant="primary" class="m-2">
            <template #button-content>
              <span style="margin-right: 8px">{{
                status_array[parseInt(status) - 1]
              }}</span>
              <i class="ri-filter-2-fill"></i>
            </template>
            <b-dropdown-item @click="filterJob(1)">Assigned</b-dropdown-item>

            <b-dropdown-item @click="filterJob(2)">In Progress</b-dropdown-item>
            <b-dropdown-item @click="filterJob(3)">Completed</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="row">
          <div class="col-sm-4" v-for="job in data" :key="job._id">
            <div class="card p-2">
              <div class="d-flex justify-content-end">
                <b-dropdown right variant="primary" class="m-1">
                  <template #button-content>
                   <i class="ri-settings-2-line"></i>
                  </template>
                  <b-dropdown-item @click="$router.push({name:'VehicleInfo',params:{id:job.vehicle._id}})"
                    >View Vehicle</b-dropdown-item
                  >
                </b-dropdown>
              </div>
          
              <div class="ad-info" v-if="job">
                <h6>Job Info</h6>

                <evidenceModal
                  v-if="job.wrap_images.length"
                  :evidences="job.wrap_images"
                  :id="job._id"
                />

                <div class="wrapping-time">
                  <div v-if="job.wrapping_schedule_date">
                    <strong>Wrapping schedule date </strong>

                    {{ moment(job.wrapping_schedule_date).format("LLL") }}
                  </div>
                  <div v-if="job.start_date">
                    <strong>Start date</strong>
                    {{ moment(job.start_date).format("LLL") }}
                  </div>
                  <div v-if="job.wrapping_started_at">
                    <strong>Wrapping started </strong>
                    {{ moment(job.wrapping_started_at).format("LLL") }}
                  </div>
                  <div v-if="job.wrapping_finished_at">
                    <strong> Wrapping finished</strong>
                    {{ moment(job.wrapping_finished_at).format("LLL") }}
                  </div>
                </div>
              </div>
              <div class="vehicle-info" v-if="job.vehicle">
                <h6>Vehicle Info</h6>

                <strong>Plate Category </strong> {{ job.vehicle.plate_category
                }}<br />
                <strong>Plate No.</strong> {{ job.vehicle.plate_number }}<br />
                <strong> Model </strong>
                {{ job.vehicle.model && job.vehicle.model.name }} (
                {{
                  job.vehicle.model && job.vehicle.model.manufacture.name
                }})<br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jobs } from "@/config/api/jobs";
import evidenceModal from "@/components/workstations/evidence-modal";
export default {
  components: {
    evidenceModal,
  },
  data() {
    return {
      loading: false,
      data: null,
      status: 1,
      status_array: ["Assigned", "In Progress", "Completed"],
    };
  },
  created() {
    this.getJobs();
  },
  methods: {
    getJobs() {
      const api = jobs.workstationId;
      api.params = {
        workstationId: this.$route.query.id,
        status: this.status,
      };

      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.data = res.data.jobs;
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterJob(status) {
      this.status = status;
      this.getJobs();
    },
  },
};
</script>

<style>
.vehicle-info,
.ad-info {
  background-color: #fafafa;
  padding: 4px 8px;
  border-radius: 8px;
}
.ad-info {
  margin-bottom: 4px;
}
.wrapping-time div {
  background-color: #fff;
  margin-bottom: 4px;
}
</style>
